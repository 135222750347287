<script>
import {
  //Action,
  ContentSection,
  Tooltip,
  MultiSelect,
  Modal,
  Loading,
} from 'epmc-patterns/components/v2'
import { Dropdown } from 'epmc-patterns/components/v3'
import {
  SECTIONS,
  DEFAULT_PLACE_TO_SEARCH,
  PLACES_TO_SEARCH,
  ARTICLE_SECTION_OPTIONS,
  ALL_PUB_TYPE_OPTIONS,
  PUB_TYPE_PUBMED_NCBI_OPTIONS,
  PREPRINTS_OPTIONS,
  ARTICLE_ID_OPTIONS,
  DATE_OPTIONS,
  FREE_FULL_TEXT_ACCESS_OPTIONS,
  OPEN_ACCESS_OR_CC_LICENSE,
} from '@/config'
import vClickOutside from 'v-click-outside'
import { getSearchPath, constructHtmlForQuery } from '@/helpers/search'
import {
  fetchStats,
  fetchJournals,
  fetchAuthors,
  fetchFundingAgencies,
  fetchGrantIds,
} from '@/api'
import { uuid } from 'vue-uuid'
import { scrollTo, getCurrentDate } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'

const DEFAULT_PLACEHOLDER_TEXT = 'Select one or more types'
const DEFAULT_FREE_FULL_TEXT_PLACEHOLDER_TEXT = 'Select a type of free access'
const DEFAULT_OPEN_ACCESS_OR_CC_LICENSE_PLACEHOLDER_TEXT =
  'Select one or more license types'
const DEFAULT_FUNDING_AGENCY_PLACEHOLDER_TEXT = 'Enter agency name to view list'
const DEFAULT_GRANT_ID_PLACEHOLDER_TEXT = 'Enter agency name to view list'

const DEFAULT_AUTHORS_PLACEHOLDER_TEXT =
  'Enter an author name or ORCID and view list'
const DEFAULT_JOURNALS_PLACEHOLDER_TEXT = 'Enter journal name and view list'

function debounce(func, wait) {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default {
  components: {
    ContentSection,
    Dropdown,
    Tooltip,
    MultiSelect,
    Modal,
    Loading,
    //TipsTooltip: () => import('./components/tooltips/TipsTooltip.vue'),
    FindExactPhraseTooltip: () =>
      import('./components/tooltips/FindExactPhraseTooltip.vue'),
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      currentDate: getCurrentDate(),
      countLoading: false,
      fundingAgenciesLoading: false,
      grantIdsLoading: false,
      journalsLoading: false,
      authorsLoading: false,
      totalCount: 0,
      query: [],
      fromDate: null,
      toDate: getCurrentDate(),
      editableFromDate: null,
      editableToDate: null,
      rowTerm: null,
      termOrPhrase: null,
      articleId: null,
      affiliation: null,
      isFindExactPhraseEnabled: false,
      logicalOperator: {
        freeText: 'OR',
        dates: 'AND',
        allPublicationTypes: 'AND',
        freeFullTextAccess: 'AND',
        openAccessOrCCLicense: 'AND',
        fundingAgency: 'AND',
        grantId: 'AND',
        journals: 'AND',
        affiliation: 'AND',
        authors: 'AND',
        articleId: 'AND',
      },
      sections: SECTIONS.map((section) => section),
      options: [
        {
          text: 'OR',
          value: 'OR',
          selected: true,
        },
        {
          text: 'AND',
          value: 'AND',
          selected: false,
        },
        {
          text: 'NOT',
          value: 'NOT',
          selected: false,
        },
      ],
      options_section2: [
        {
          text: 'AND',
          value: 'AND',
          selected: true,
        },
        {
          text: 'OR',
          value: 'OR',
          selected: false,
        },
        {
          text: 'NOT',
          value: 'NOT',
          selected: false,
        },
      ],
      selectedFundingAgencies: [],
      selectedGrantIds: [],
      selectedJournals: [],
      selectedAuthors: [],
      defaultPlace: DEFAULT_PLACE_TO_SEARCH,
      placesToSearch: PLACES_TO_SEARCH,
      articleSectionOptions: ARTICLE_SECTION_OPTIONS,
      allPublicationTypeOptions: ALL_PUB_TYPE_OPTIONS.concat(
        PUB_TYPE_PUBMED_NCBI_OPTIONS
      ),
      authorOptions: [],
      fundingAgencyOptions: [],
      grantIdOptions: [],
      journalOptions: [],
      preprintOptions: PREPRINTS_OPTIONS,
      articleIdOptions: ARTICLE_ID_OPTIONS,
      dateOptions: DATE_OPTIONS,
      freeFullTextAccessOptions: FREE_FULL_TEXT_ACCESS_OPTIONS,
      openAccessOrCCLicenseOptions: OPEN_ACCESS_OR_CC_LICENSE,
      multiSelectStyle:
        'height: 42px; width: 284px; border: 1px solid #1376ae; caret-color: transparent; cursor:pointer; box-shadow: none;',
      selectedOptionStyle: 'color:#494949; font-weight: 400',
      closeEditTermTooltip: false,
      closeEditDatesTooltip: false,
      showIntroductionModal: false,
      showNoSearchResultsModal: false,
      savedQuery: [],
      betaAdvanceSearchDescription:
        'Try this new version (additional filters will be added over time) and give feedback in the exit survey!',
      allPublicationTypeOptionsPlaceholder: DEFAULT_PLACEHOLDER_TEXT,
      fundingAgencyOptionsPlaceholder: DEFAULT_FUNDING_AGENCY_PLACEHOLDER_TEXT,
      journalOptionsPlaceholder: DEFAULT_JOURNALS_PLACEHOLDER_TEXT,
      authorOptionsPlaceholder: DEFAULT_AUTHORS_PLACEHOLDER_TEXT,
      preprintServerOptionsPlaceholder: DEFAULT_PLACEHOLDER_TEXT,
      freeFullTextAccessOptionsPlaceholder:
        DEFAULT_FREE_FULL_TEXT_PLACEHOLDER_TEXT,
      openAccessOrCCLicenseOptionsPlaceholder:
        DEFAULT_OPEN_ACCESS_OR_CC_LICENSE_PLACEHOLDER_TEXT,
      grantIdOptionsPlaceholder: DEFAULT_GRANT_ID_PLACEHOLDER_TEXT,
    }
  },
  computed: {
    isAnyItemSelected() {
      const activeSection = this.sections.find((item) => item.visible === true)
      if (activeSection)
        if (activeSection.id === this.sections[0].id) return !!this.termOrPhrase
        else if (activeSection.id === this.sections[1].id) {
          return (
            this.allPublicationTypeOptions.some((item) => item.selected) ||
            this.preprintOptions.some((item) => item.selected) ||
            (this.articleIdOptions.some(
              (item) => item.selected && item.value !== null
            ) &&
              !!this.articleId) ||
            (this.dateOptions.some(
              (item) => item.selected && item.value !== null
            ) &&
              this.fromDate &&
              this.toDate)
          )
        } else if (activeSection.id === this.sections[2].id) {
          return (
            this.freeFullTextAccessOptions.find(
              (item) => item.selected && item.value !== null
            ) ||
            this.openAccessOrCCLicenseOptions.find((item) => item.selected) ||
            this.selectedFundingAgencies.some((item) => item.selected) ||
            this.selectedGrantIds.some((item) => item.selected)
          )
        } else if (activeSection.id === this.sections[3].id) {
          return (
            this.selectedAuthors.some((item) => item.selected) ||
            this.selectedJournals.some((item) => item.selected) ||
            !!this.affiliation
          )
        }
      return false
    },
    isMobileScreen() {
      return window.innerWidth <= 768 // Assuming 768 is the breakpoint for mobile devices
    },
  },
  watch: {
    query: {
      handler(newQuery) {
        localStorage.setItem('searchQuery', JSON.stringify(newQuery))
        if (this.query.length > 0) scrollTo('#searchQueryBottomSheet')
      },
      deep: true,
    },
  },
  created() {
    const storedQuery = localStorage.getItem('searchQuery')
    if (storedQuery) {
      try {
        this.query = JSON.parse(storedQuery)
        this.updateQueryCounts()
      } catch (error) {}
    }
    this.debouncedHandleFundingAgencyInputChange = debounce(
      this.handleFundingAgencyInputChange.bind(this),
      300
    )
    this.debouncedHandleGrantIdInputChange = debounce(
      this.handleGrantIdInputChange.bind(this),
      300
    )
    this.debouncedHandleJournalInputChange = debounce(
      this.handleJournalInputChange.bind(this),
      300
    )
    this.debouncedHandleAuthorInputChange = debounce(
      this.handleAuthorInputChange.bind(this),
      300
    )
  },
  mounted() {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBetaAdvanceSearch')
    if (!hasVisitedBefore) {
      this.showIntroductionModal = true
    }
  },
  methods: {
    renderQueryHtml(item) {
      return constructHtmlForQuery(item)
    },
    onActionButtonClick() {},
    createOptions() {
      return [
        {
          text: 'OR',
          value: 'OR',
          selected: true,
        },
        {
          text: 'AND',
          value: 'AND',
          selected: false,
        },
        {
          text: 'NOT',
          value: 'NOT',
          selected: false,
        },
      ]
    },
    getSelectedOption(listName) {
      const selectedOption = this[listName].find(
        (item) => item.selected === true && item.value !== null
      )
      return selectedOption
    },
    onTermOrPhraseChange(event) {
      const value = event.target.value
      this.termOrPhrase = value
      if (value === '') {
        this.isFindExactPhraseEnabled = false
      }
      if (/^(['"]).*\1$/.test(value)) {
        this.isFindExactPhraseEnabled = true
      }
    },
    onAffiliationChange(event) {
      const value = event.target.value
      this.affiliation = value
    },
    onArticleIdChange(event) {
      const value = event.target.value
      this.articleId = value
    },
    onSectionOpened(sectionTitle) {
      const { sections } = this
      sections.forEach((section) => {
        if (section.title !== sectionTitle) {
          section.visible = false
        }
      })
    },
    onLogicalOperatorChange(operator, context) {
      const { value } = operator
      this.logicalOperator[context] = value
    },
    onQueryLogicalOperatorChange(operator, id) {
      const rowToUpdate = this.query.find((item) => item.id === id)
      const rowToUpdateIndex = this.query.findIndex((item) => item.id === id)
      rowToUpdate.logicalOperator = operator.value
      if (operator.value === 'NOT') {
        //const regex = /^\([^()]*\)$/;
        const regex = /^\(.*\)$/
        const isEnclosedInParantheses = regex.test(rowToUpdate.query)
        if (!isEnclosedInParantheses)
          rowToUpdate.query = `(${rowToUpdate.query})`
      }
      this.$set(this.query, rowToUpdateIndex, rowToUpdate)
      this.countLoading = true
      this.getTotalCount()
    },
    onFindExactPhraseClick(e) {
      if (e.target.checked) {
        this.termOrPhrase = `"${this.termOrPhrase}"`
        this.isFindExactPhraseEnabled = true
        return
      }
      this.termOrPhrase = this.termOrPhrase.replace(/"/g, '')
      this.isFindExactPhraseEnabled = false
    },
    isAnyPlaceSelected() {
      return (
        this.placesToSearch.some((item) => item.selected) ||
        this.articleSectionOptions.some((item) => item.selected)
      )
    },
    onPlaceToSearchClick(id) {
      const place = this.placesToSearch.find((item) => item.id === id)
      if (place) {
        this.$set(place, 'selected', !place.selected)
        this.$set(this.defaultPlace, 'selected', false)
      }
      if (!this.isAnyPlaceSelected()) {
        this.$set(this.defaultPlace, 'selected', true)
      }
    },
    onDefaultPlaceToSearchClick() {
      this.$set(this.defaultPlace, 'selected', true)
      this.placesToSearch = this.placesToSearch.map((item) => {
        return {
          ...item,
          selected: false,
        }
      })
      this.articleSectionOptions = this.articleSectionOptions.map((item) => {
        return {
          ...item,
          selected: false,
        }
      })
      this.multiSelectStyle = this.multiSelectStyle.replace(
        'background-color: #dfecf5;',
        ''
      )
    },
    onArticleSectionOptionsChanged() {
      const isAnyArticleOptionSelected = this.articleSectionOptions.find(
        (item) => item.selected === true
      )

      let updatedStyle = this.multiSelectStyle // Create a temporary variable
      if (isAnyArticleOptionSelected) {
        this.$set(this.defaultPlace, 'selected', false)
        if (!updatedStyle.includes('background-color: #dfecf5;')) {
          updatedStyle += ' background-color: #dfecf5;'
        }
      } else {
        updatedStyle = updatedStyle.replace('background-color: #dfecf5;', '')
      }
      this.multiSelectStyle = updatedStyle // Assign back to trigger reactivity
      if (!this.isAnyPlaceSelected()) {
        this.$set(this.defaultPlace, 'selected', true)
      }
    },
    onAllPublicationTypeOptionsChanged() {
      const selectedPublicationTypeOptions = this.allPublicationTypeOptions
        .filter((item) => item.selected === true)
        .map((item) => item.text)
        .join(', ')
      if (selectedPublicationTypeOptions === '') {
        this.allPublicationTypeOptionsPlaceholder = DEFAULT_PLACEHOLDER_TEXT
      } else
        this.allPublicationTypeOptionsPlaceholder =
          selectedPublicationTypeOptions
    },
    onFundingAgencyOptionsChanged(event) {
      if (event.selected === false) {
        this.selectedFundingAgencies = this.selectedFundingAgencies.filter(
          (fundingAgency) => fundingAgency.value !== event.value
        )
      }
      const selectedFundingAgencyOptions = this.fundingAgencyOptions.filter(
        (item) => {
          const isFundingAgencyAlreadySelected =
            this.selectedFundingAgencies.find(
              (selectedFundingAgency) =>
                item.value === selectedFundingAgency.value
            )
          return item.selected === true && !isFundingAgencyAlreadySelected
        }
      )
      this.selectedFundingAgencies = [
        ...new Set([
          ...this.selectedFundingAgencies,
          ...selectedFundingAgencyOptions,
        ]),
      ]

      const selectedFundingAgencyOptionsText = this.selectedFundingAgencies
        .map((item) => item.text)
        .join(', ')
      if (selectedFundingAgencyOptionsText === '') {
        this.fundingAgencyOptionsPlaceholder =
          DEFAULT_FUNDING_AGENCY_PLACEHOLDER_TEXT
      } else
        this.fundingAgencyOptionsPlaceholder = selectedFundingAgencyOptionsText
    },
    onGrantIdOptionsChanged(event) {
      if (event.selected === false) {
        this.selectedGrantIds = this.selectedGrantIds.filter(
          (grantId) => grantId.value !== event.value
        )
      }
      const selectedGrantIdOptions = this.grantIdOptions.filter((item) => {
        const isGrantIdAlreadySelected = this.selectedGrantIds.find(
          (selectedGrantId) => item.value === selectedGrantId.value
        )
        return item.selected === true && !isGrantIdAlreadySelected
      })
      this.selectedGrantIds = [
        ...new Set([...this.selectedGrantIds, ...selectedGrantIdOptions]),
      ]

      const selectedGrantIdOptionsText = this.selectedGrantIds
        .map((item) => item.text)
        .join(', ')
      if (selectedGrantIdOptionsText === '') {
        this.grantIdOptionsPlaceholder = DEFAULT_GRANT_ID_PLACEHOLDER_TEXT
      } else this.grantIdOptionsPlaceholder = selectedGrantIdOptionsText
    },
    onJournalOptionsChanged(event) {
      if (event.selected === false) {
        this.selectedJournals = this.selectedJournals.filter(
          (journal) => journal.value !== event.value
        )
      }
      const selectedJournalOptions = this.journalOptions.filter((item) => {
        const isJournalAlreadySelected = this.selectedJournals.find(
          (selectedJournal) => item.value === selectedJournal.value
        )
        return item.selected === true && !isJournalAlreadySelected
      })
      this.selectedJournals = [
        ...new Set([...this.selectedJournals, ...selectedJournalOptions]),
      ]

      const selectedJournalOptionsText = this.selectedJournals
        .map((item) => item.text)
        .join(', ')
      if (selectedJournalOptionsText === '') {
        this.journalOptionsPlaceholder = DEFAULT_JOURNALS_PLACEHOLDER_TEXT
      } else this.journalOptionsPlaceholder = selectedJournalOptionsText
    },
    onAuthorOptionsChanged(event) {
      if (event.selected === false) {
        this.selectedAuthors = this.selectedAuthors.filter(
          (author) => author.value !== event.value
        )
      }
      const selectedAuthorOptions = this.authorOptions.filter((item) => {
        const isAuthorAlreadySelected = this.selectedAuthors.find(
          (selectedAuthor) => item.value === selectedAuthor.value
        )
        return item.selected === true && !isAuthorAlreadySelected
      })
      this.selectedAuthors = [
        ...new Set([...this.selectedAuthors, ...selectedAuthorOptions]),
      ]

      const selectedAuthorOptionsText = this.selectedAuthors
        .map((item) => item.text)
        .join(', ')
      if (selectedAuthorOptionsText === '') {
        this.authorOptionsPlaceholder = DEFAULT_AUTHORS_PLACEHOLDER_TEXT
      } else this.authorOptionsPlaceholder = selectedAuthorOptionsText
    },
    onOpenAccessOrCCLicenseOptionsChanged() {
      const selectedOpenAccessOrCCLicenseOptions =
        this.openAccessOrCCLicenseOptions
          .filter((item) => item.selected === true)
          .map((item) => item.text)
          .join(', ')
      if (selectedOpenAccessOrCCLicenseOptions === '') {
        this.openAccessOrCCLicenseOptionsPlaceholder = DEFAULT_PLACEHOLDER_TEXT
      } else
        this.openAccessOrCCLicenseOptionsPlaceholder =
          selectedOpenAccessOrCCLicenseOptions
    },
    async handleFundingAgencyInputChange(term) {
      if (term.length > 1) {
        this.fundingAgenciesLoading = true
        try {
          const response = await fetchFundingAgencies(term)
          this.fundingAgencyOptions = response.map((fundingAgency) => {
            // Check if the current journal is in selectedJournals
            const isSelected = this.selectedFundingAgencies.some(
              (selected) => selected.value === fundingAgency.coreValue
            )

            return {
              value: fundingAgency.coreValue,
              text: fundingAgency.displayValue,
              selected: isSelected,
            }
          })
          this.fundingAgenciesLoading = false
        } catch (error) {
          this.fundingAgenciesLoading = false
        }
      }
    },
    async handleGrantIdInputChange(term) {
      if (term.length > 1) {
        this.grantIdsLoading = true
        try {
          const response = await fetchGrantIds(term)
          const grantIds = response.map((grantId) => {
            // Check if the current journal is in selectedJournals
            const isSelected = this.selectedGrantIds.some(
              (selected) => selected.value === grantId.coreValue
            )

            return {
              value: grantId.coreValue,
              text: grantId.displayValue,
              selected: isSelected,
            }
          })
          this.grantIdOptions = grantIds.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.value === current.value)) {
              accumulator.push(current)
            }
            return accumulator
          }, [])

          this.grantIdsLoading = false
        } catch (error) {
          this.grantIdsLoading = false
        }
      }
    },
    async handleJournalInputChange(term) {
      if (term.length > 1) {
        this.journalsLoading = true
        try {
          const response = await fetchJournals(term)
          this.journalOptions = response.map((journal) => {
            // Check if the current journal is in selectedJournals
            const isSelected = this.selectedJournals.some(
              (selected) => selected.value === journal.coreValue
            )

            if (isSelected) {
              return {
                value: journal.coreValue,
                text: journal.displayValue,
                selected: true,
              }
            }

            return {
              value: journal.coreValue,
              text: journal.displayValue,
              selected: false,
            }
          })
          this.journalsLoading = false
        } catch (error) {
          this.journalsLoading = false
        }
      }
    },
    async handleAuthorInputChange(term) {
      if (term.length > 1) {
        this.authorsLoading = true
        try {
          const response = await fetchAuthors(term)
          this.authorOptions = response.map((author) => {
            // Check if the current journal is in selectedJournals
            const isSelected = this.selectedAuthors.some(
              (selected) => selected.value === author.coreValue
            )

            if (isSelected) {
              return {
                value: author.coreValue,
                text: author.displayValue,
                orcidId: author.orcidId ? author.orcidId : null,
                selected: true,
              }
            }

            return {
              value: author.coreValue,
              text: author.displayValue,
              orcidId: author.orcidId ? author.orcidId : null,
              selected: false,
            }
          })
          this.authorsLoading = false
        } catch (error) {
          this.authorsLoading = false
        }
      }
    },
    onDeleteRowClick(id) {
      const rowToDelete = this.query.find((item) => item.id === id)
      if (rowToDelete) {
        const index = this.query.findIndex((item) => item.id === id)
        if (index === 0 && this.query.length > 1) {
          const secondRow = this.query[1]
          delete secondRow.logicalOperator
          this.$set(this.query, 1, secondRow)
        }
      }
      this.query = this.query.filter((item) => item.id !== id)
      this.countLoading = true
      this.getTotalCount()
    },
    onArticleIdOptionChange() {},
    onDateOptionChange() {},
    onRowContentChange(event, item) {
      event.preventDefault()
      const updatedTerm = event.target.rowTerm.value
      const { id, term } = item
      const rowToUpdate = this.query.find((item) => item.id === id)
      const index = this.query.findIndex((item) => item.id === id)
      if (rowToUpdate.selectedArticleIdOptionValue) {
        const { articleIdSearchQuery } = this.createArticleIdSearchQuery(
          updatedTerm,
          rowToUpdate.selectedArticleIdOptionValue
        )
        rowToUpdate.query = articleIdSearchQuery
      } else {
        if (rowToUpdate.query.includes('AFF:')) {
          rowToUpdate.query = rowToUpdate.query.replaceAll(
            `("${term}")`,
            `("${updatedTerm}")`
          )
        } else
          rowToUpdate.query = rowToUpdate.query.replaceAll(
            `(${term})`,
            `(${updatedTerm})`
          )
      }
      rowToUpdate.term = updatedTerm
      rowToUpdate.countLoading = true
      this.$set(this.query, index, rowToUpdate)
      this.closeEditTermTooltip = true
      this.getQueryCount(index, rowToUpdate)
      this.countLoading = true
      this.getTotalCount()
    },
    onRowDateChange(event, item) {
      event.preventDefault()
      event.target.rowDateFrom.setCustomValidity('')
      const updatedFromDate = event.target.rowDateFrom.value
      const updatedToDate = event.target.rowDateTo.value

      const { id, fromDate, toDate } = item
      const rowToUpdate = this.query.find((item) => item.id === id)
      const index = this.query.findIndex((item) => item.id === id)
      rowToUpdate.query = rowToUpdate.query.replaceAll(
        `[${fromDate} TO ${toDate}]`,
        `[${updatedFromDate} TO ${updatedToDate}]`
      )
      rowToUpdate.fromDate = updatedFromDate
      rowToUpdate.toDate = updatedToDate
      rowToUpdate.countLoading = true
      this.$set(this.query, index, rowToUpdate)
      this.closeEditDatesTooltip = true
      this.getQueryCount(index, rowToUpdate)
      this.countLoading = true
      this.getTotalCount()
    },
    getLogicalOperatorDropdownClass() {
      const selectedLogicalOperator = this.options.find(
        (option) => option.selected === true
      )

      return selectedLogicalOperator.value.toLowerCase()
    },
    canSubmit() {
      const { query, termOrPhrase } = this
      return query.length > 0 && termOrPhrase.length > 0
    },
    onTopicsOrKeywordsSubmit(e) {
      e.preventDefault()
      const { termOrPhrase, query, logicalOperator } = this
      const { placesToSearchQuery, placesToSearchBracketRequired } =
        this.createPlacesToSearchQuery(termOrPhrase)
      const { articleSectionSearchQuery, articleSectionBracketRequired } =
        this.createArticleSectionSearchQuery(termOrPhrase)
      if (placesToSearchQuery && articleSectionSearchQuery) {
        // const isExtraBracketRequired = logicalOperator['freeText'] === 'NOT'
        // extra bracket is always required when queries exist from both sections. (CIT-9906)
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['freeText'],
          query: `(${placesToSearchQuery} OR ${articleSectionSearchQuery})`,
          term: termOrPhrase,
        })
      } else if (placesToSearchQuery) {
        const isExtraBracketRequired =
          logicalOperator['freeText'] === 'NOT' || placesToSearchBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['freeText'],
          query: isExtraBracketRequired
            ? `(${placesToSearchQuery})`
            : placesToSearchQuery,
          term: termOrPhrase,
        })
      } else if (articleSectionSearchQuery) {
        const isExtraBracketRequired =
          logicalOperator['freeText'] === 'NOT' || articleSectionBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['freeText'],
          query: isExtraBracketRequired
            ? `(${articleSectionSearchQuery})`
            : articleSectionSearchQuery,
          term: termOrPhrase,
        })
      }
      this.resetTopicsAndKeywordsForm()
      const itemIndex = this.query.length - 1
      const rowToUpdate = this.query[this.query.length - 1]
      rowToUpdate.countLoading = true
      this.getQueryCount(itemIndex, rowToUpdate)
      this.countLoading = true
      this.getTotalCount()
      scrollTo('#searchQueryBottomSheet')
    },
    onPublicationTypeOrJournalOrArticleIdSubmit(e) {
      e.preventDefault()
      const { articleId, query, logicalOperator, fromDate, toDate } = this

      let datesSearchQuery = ''
      let datesQueryBracketRequired = false
      const selectedDateOption = this.getSelectedOption('dateOptions')
      if (fromDate && toDate && selectedDateOption) {
        ;({ datesSearchQuery, datesQueryBracketRequired } =
          this.createDatesSearchQuery())
      }

      const { allPublicationSearchQuery, allPublicationBracketRequired } =
        this.createAllPublicationQuery()

      let articleIdSearchQuery = ''
      let articleIdQueryBracketRequired = false
      const selectedArticleIdOptions =
        this.getSelectedOption('articleIdOptions')
      if (articleId && selectedArticleIdOptions) {
        ;({ articleIdSearchQuery, articleIdQueryBracketRequired } =
          this.createArticleIdSearchQuery(articleId))
      }

      if (datesSearchQuery) {
        const selectedDateOption = this.dateOptions.find(
          (dateOption) => dateOption.selected === true
        )
        const selectedDateOptionValue = selectedDateOption.value
        const isExtraBracketRequired =
          logicalOperator['dates'] === 'NOT' || datesQueryBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['dates'],
          query: isExtraBracketRequired
            ? `(${datesSearchQuery})`
            : datesSearchQuery,
          fromDate,
          toDate,
          selectedDateOptionValue,
          countLoading: true,
        })
      }
      if (allPublicationSearchQuery) {
        const isExtraBracketRequired =
          logicalOperator['allPublicationTypes'] === 'NOT' ||
          allPublicationBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator:
            query.length > 0 && logicalOperator['allPublicationTypes'],
          query: isExtraBracketRequired
            ? `(${allPublicationSearchQuery})`
            : allPublicationSearchQuery,
          countLoading: true,
        })
      }
      if (articleIdSearchQuery) {
        const selectedArticleIdOption = this.articleIdOptions.find(
          (articleIdOption) => articleIdOption.selected === true
        )
        const selectedArticleIdOptionValue = selectedArticleIdOption.value
        const isExtraBracketRequired =
          logicalOperator['articleId'] === 'NOT' ||
          articleIdQueryBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['articleId'],
          query: isExtraBracketRequired
            ? `(${articleIdSearchQuery})`
            : articleIdSearchQuery,
          term: articleId,
          selectedArticleIdOptionValue,
          countLoading: true,
        })
      }
      this.resetPublicationTypeOrJournalOrArticleIdForm()
      this.updateQueryCounts()
      scrollTo('#searchQueryBottomSheet')
    },
    onFilterByAuthorOrAffiliationOrJournalSubmit(e) {
      e.preventDefault()
      const { query, logicalOperator } = this
      const { authorOptionsSearchQuery, authorOptionsBracketRequired } =
        this.createAuthorsSearchQuery()
      const affiliationSearchQuery = this.createAffiliationSearchQuery()
      const { journalOptionsSearchQuery, journalOptionsBracketRequired } =
        this.createJournalsSearchQuery()

      if (authorOptionsSearchQuery) {
        const isExtraBracketRequired =
          logicalOperator['authors'] === 'NOT' || authorOptionsBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['authors'],
          query: isExtraBracketRequired
            ? `(${authorOptionsSearchQuery})`
            : authorOptionsSearchQuery,
          countLoading: true,
        })
      }
      if (affiliationSearchQuery) {
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['affiliations'],
          query: affiliationSearchQuery,
          term: this.affiliation,
          countLoading: true,
        })
      }
      if (journalOptionsSearchQuery) {
        const isExtraBracketRequired =
          logicalOperator['journals'] === 'NOT' || journalOptionsBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['journals'],
          query: isExtraBracketRequired
            ? `(${journalOptionsSearchQuery})`
            : journalOptionsSearchQuery,
          countLoading: true,
        })
      }
      this.resetFilterByAuthorOrAffiliationOrJournalForm()
      this.updateQueryCounts()
      scrollTo('#searchQueryBottomSheet')
    },
    onFreeFullTextAccessOrFundingOrLicenseSubmit(event) {
      event.preventDefault()
      const { query, logicalOperator } = this
      const selectedFreeFullTextOption = this.getSelectedOption(
        'freeFullTextAccessOptions'
      )
      const selectedOpenAccessOrCCLicenseOptions = this.getSelectedOption(
        'openAccessOrCCLicenseOptions'
      )
      const selectedFundingAgencyOption = this.getSelectedOption(
        'selectedFundingAgencies'
      )
      const selectedGrantIdOption = this.getSelectedOption('selectedGrantIds')

      if (selectedFreeFullTextOption) {
        const freeFullTextAccessSearchQuery = selectedFreeFullTextOption.value
        const isExtraBracketRequired =
          logicalOperator['freeFullTextAccess'] === 'NOT'
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['journals'],
          query: isExtraBracketRequired
            ? `(${freeFullTextAccessSearchQuery})`
            : freeFullTextAccessSearchQuery,
          countLoading: true,
        })
      }

      if (selectedOpenAccessOrCCLicenseOptions) {
        const {
          openAccessOrCCLicenseSearchQuery,
          openAccessOrCCLicenseBracketRequired,
        } = this.createOpenAccessOrCCLicenseSearchQuery()
        const isExtraBracketRequired =
          logicalOperator['openAccessOrCCLicense'] === 'NOT' ||
          openAccessOrCCLicenseBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator:
            query.length > 0 && logicalOperator['openAccessOrCCLicense'],
          query: isExtraBracketRequired
            ? `(${openAccessOrCCLicenseSearchQuery})`
            : openAccessOrCCLicenseSearchQuery,
          countLoading: true,
        })
      }

      if (selectedFundingAgencyOption) {
        const {
          fundingAgencyOptionsSearchQuery,
          fundingAgencyOptionsBracketRequired,
        } = this.createFundingAgencySearchQuery()
        const isExtraBracketRequired =
          logicalOperator['fundingAgency'] === 'NOT' ||
          fundingAgencyOptionsBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['fundingAgency'],
          query: isExtraBracketRequired
            ? `(${fundingAgencyOptionsSearchQuery})`
            : fundingAgencyOptionsSearchQuery,
          countLoading: true,
        })
      }

      if (selectedGrantIdOption) {
        const { grantIdOptionsSearchQuery, grantIdOptionsBracketRequired } =
          this.createGrantIdSearchQuery()
        const isExtraBracketRequired =
          logicalOperator['grantId'] === 'NOT' || grantIdOptionsBracketRequired
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator['grantId'],
          query: isExtraBracketRequired
            ? `(${grantIdOptionsSearchQuery})`
            : grantIdOptionsSearchQuery,
          countLoading: true,
        })
      }

      this.resetFreeFullTextAccessOrFundingOrLicenseForm()
      this.updateQueryCounts()
      scrollTo('#searchQueryBottomSheet')
    },
    async getQueryCount(itemIndex, rowToUpdate) {
      if (!rowToUpdate || !rowToUpdate.query) {
        this.countLoading = false
        rowToUpdate.countLoading = false
        return
      }
      try {
        const response = await fetchStats(rowToUpdate.query)
        rowToUpdate.count = response.hitCount
        this.$set(this.query, itemIndex, rowToUpdate)
      } catch (error) {
      } finally {
        this.countLoading = false
        rowToUpdate.countLoading = false
      }
    },
    /**getQueryCount(itemIndex, rowToUpdate) {
      if (!rowToUpdate || !rowToUpdate.query) {
        this.countLoading = false
        return
      }
      const response = fetchStats(rowToUpdate.query)
      response
        .then((result) => {
          rowToUpdate.count = result.hitCount
          this.$set(this.query, itemIndex, rowToUpdate)
          this.countLoading = false
        })
        .catch(() => {
          this.countLoading = false
        })
    },**/
    async getTotalCount() {
      const query = this.createSearchQuery(this.query)
      try {
        const response = await fetchStats(query)
        this.totalCount = response.hitCount
      } catch (error) {
      } finally {
        this.countLoading = false
      }
    },
    /**getTotalCount() {
      const query = this.createSearchQuery(this.query)
      const response = fetchStats(query)
      response
        .then((result) => {
          this.totalCount = result.hitCount
          this.countLoading = false
        })
        .catch(() => {
          this.countLoading = false
        })
    },**/
    async updateQueryCounts() {
      const queryCountPromises = this.query.map((row, index) =>
        this.getQueryCount(index, row)
      )
      await Promise.allSettled(queryCountPromises)
      this.countLoading = true
      await this.getTotalCount()
    },
    async onSearchQueryClick(searchQuery, itemCount) {
      const isAnyItemContainsNoResult = this.query.find(
        (item) => item.count === 0
      )
      const finalQuery = searchQuery
        ? searchQuery
        : this.createSearchQuery(this.query)
      if (searchQuery) {
        if (itemCount === 0) {
          this.showNoSearchResultsModal = true
          this.savedQuery = finalQuery
          return
        }
      } else if (this.totalCount === 0 || isAnyItemContainsNoResult) {
        this.savedQuery = finalQuery
        this.showNoSearchResultsModal = true
        return
      }
      this.navigateToSearchQuery(finalQuery)
    },
    async navigateToSearchQuery(finalQuery) {
      await registerMatomoEvent(
        'Adv Search',
        'Click beta Adv search button',
        finalQuery
      )
      const searchRoute = getSearchPath()
      const query = encodeURIComponent(finalQuery || 'SRC:*')
      location.href = `/${searchRoute}?query=${query}&page=1`
    },
    onClearQueryClick() {
      this.query = []
    },
    createSearchQuery(data) {
      let searchQuery = ''
      let startingBrackets = ''
      data.map(({ query, logicalOperator }, index) => {
        // Brackets logic to build the query (((a + b) + c) + d)
        if (index > 1) {
          searchQuery += ')'
        }
        if (index > 0) {
          startingBrackets = startingBrackets + '('
          searchQuery += ` ${logicalOperator} ` // Add logical operator if not the first query
        }
        searchQuery += query
        if (index > 0 && index + 1 === this.query.length) {
          searchQuery += ')'
        }
      })
      return `${startingBrackets}${searchQuery}`
    },
    resetTopicsAndKeywordsForm() {
      const formElement = document.getElementById('topicsAndKeywordsForm')
      if (formElement) {
        formElement.reset()
      }
      this.termOrPhrase = null
      const resetedPlacesToSearch = this.placesToSearch.map((placeToSearch) => {
        return {
          ...placeToSearch,
          selected: false,
        }
      })
      const resetedArticleSectionOptions = this.articleSectionOptions.map(
        (articleSectionOption) => {
          return {
            ...articleSectionOption,
            selected: false,
          }
        }
      )
      this.multiSelectStyle = this.multiSelectStyle.replace(
        'background-color: #dfecf5;',
        ''
      )

      this.placesToSearch = resetedPlacesToSearch
      this.articleSectionOptions = resetedArticleSectionOptions
      this.$set(this.defaultPlace, 'selected', true)
    },
    resetPublicationTypeOrJournalOrArticleIdForm() {
      const formElement = document.getElementById(
        'publicatiobTypeOrJournalOrArticleIdForm'
      )
      if (formElement) {
        formElement.reset()
      }
      this.articleId = null
      const resetedAllPublicationTypeOptions =
        this.allPublicationTypeOptions.map((publicationType) => {
          return {
            ...publicationType,
            selected: false,
          }
        })

      const resetedDateTypeOptions = this.dateOptions.map((dateType, index) => {
        return {
          ...dateType,
          selected: index === 0 ? true : false,
        }
      })

      const resetedArticleIdOptions = this.articleIdOptions.map(
        (articleId, index) => {
          return {
            ...articleId,
            selected: index === 0 ? true : false,
          }
        }
      )

      this.dateOptions = resetedDateTypeOptions
      this.allPublicationTypeOptions = resetedAllPublicationTypeOptions
      this.allPublicationTypeOptionsPlaceholder = DEFAULT_PLACEHOLDER_TEXT
      this.journalOptions = []
      this.selectedJournals = []
      this.fromDate = null
      this.toDate = this.currentDate
      this.articleIdOptions = resetedArticleIdOptions
      this.journalOptionsPlaceholder = DEFAULT_JOURNALS_PLACEHOLDER_TEXT
      this.allPublicationTypeOptionsPlaceholder = DEFAULT_PLACEHOLDER_TEXT
    },
    resetFreeFullTextAccessOrFundingOrLicenseForm() {
      const formElement = document.getElementById(
        'freeFullTextAccessOrFundingOrLicenseForm'
      )
      if (formElement) {
        formElement.reset()
      }
      const resetedFreeFullTextAccessOptions =
        this.freeFullTextAccessOptions.map(
          (freeFullTextAccessOption, index) => {
            return {
              ...freeFullTextAccessOption,
              selected: index === 0 ? true : false,
            }
          }
        )

      const resetedOpenAccessOrCCLicenseOptions =
        this.openAccessOrCCLicenseOptions.map((openAccessOrCCLicenseOption) => {
          return {
            ...openAccessOrCCLicenseOption,
            selected: false,
          }
        })

      this.freeFullTextAccessOptions = resetedFreeFullTextAccessOptions
      this.openAccessOrCCLicenseOptions = resetedOpenAccessOrCCLicenseOptions
      this.fundingAgencyOptions = []
      this.selectedFundingAgencies = []
      this.grantIdOptions = []
      this.selectedGrantIds = []

      this.openAccessOrCCLicenseOptionsPlaceholder =
        DEFAULT_OPEN_ACCESS_OR_CC_LICENSE_PLACEHOLDER_TEXT
      this.fundingAgencyOptionsPlaceholder =
        DEFAULT_FUNDING_AGENCY_PLACEHOLDER_TEXT
      this.grantIdOptionsPlaceholder = DEFAULT_GRANT_ID_PLACEHOLDER_TEXT
    },
    resetFilterByAuthorOrAffiliationOrJournalForm() {
      const formElement = document.getElementById(
        'filterByAuthorOrAffiliationOrJournalForm'
      )
      if (formElement) {
        formElement.reset()
      }
      this.journalOptions = []
      this.authorOptions = []
      this.selectedJournals = []
      this.affiliation = null
      this.selectedAuthors = []
      this.journalOptionsPlaceholder = DEFAULT_JOURNALS_PLACEHOLDER_TEXT
      this.authorOptionsPlaceholder = DEFAULT_AUTHORS_PLACEHOLDER_TEXT
    },
    createPlacesToSearchQuery(termOrPhrase) {
      let selectedPlacesToSearch = []
      let placesToSearchQuery = ''
      this.placesToSearch.map(({ value, selected }) => {
        if (selected === true) selectedPlacesToSearch.push(value)
      })
      if (selectedPlacesToSearch.length > 0) {
        if (
          selectedPlacesToSearch.includes('TITLE') &&
          selectedPlacesToSearch.includes('ABSTRACT')
        ) {
          placesToSearchQuery = `TITLE_ABS:(${termOrPhrase})`
          if (selectedPlacesToSearch.length > 2) {
            placesToSearchQuery = placesToSearchQuery + ' OR '
          }
          const updatedList = selectedPlacesToSearch.filter(
            (item) => item !== 'TITLE' && item !== 'ABSTRACT'
          )
          selectedPlacesToSearch = updatedList
        }
        selectedPlacesToSearch.map((selectedPlace, index) => {
          placesToSearchQuery =
            placesToSearchQuery +
            `${index > 0 ? ' OR ' : ''}${
              selectedPlace ? selectedPlace + ':' : ''
            }(${termOrPhrase})`
        })
        placesToSearchQuery.replace(' ', 'OR')
      } else if (!this.isAnyPlaceSelected())
        placesToSearchQuery = `(${termOrPhrase})`
      // Add outer bracket when multiple conditions are selected
      const placesToSearchBracketRequired =
        selectedPlacesToSearch.length > 1 ||
        (selectedPlacesToSearch.length === 1 &&
          placesToSearchQuery.includes('TITLE_ABS'))
      return { placesToSearchQuery, placesToSearchBracketRequired }
    },
    createArticleSectionSearchQuery(termOrPhrase) {
      const { articleSectionOptions } = this
      let articleSectionSearchQuery = ''
      const selectedOptions = articleSectionOptions.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value }, index) => {
        articleSectionSearchQuery =
          articleSectionSearchQuery +
          `${index > 0 ? ' OR ' : ''}${value}:(${termOrPhrase})`
      })
      articleSectionSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const articleSectionBracketRequired = selectedOptions.length > 1
      return { articleSectionSearchQuery, articleSectionBracketRequired }
    },
    createAllPublicationQuery() {
      const { allPublicationTypeOptions } = this
      let allPublicationSearchQuery = ''
      const selectedOptions = allPublicationTypeOptions.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value }, index) => {
        allPublicationSearchQuery =
          allPublicationSearchQuery + `${index > 0 ? ' OR ' : ''}${value}`
      })
      allPublicationSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const allPublicationBracketRequired = selectedOptions.length > 1
      return { allPublicationSearchQuery, allPublicationBracketRequired }
    },
    createJournalsSearchQuery() {
      const { selectedJournals } = this
      let journalOptionsSearchQuery = ''
      const selectedOptions = selectedJournals.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value }, index) => {
        journalOptionsSearchQuery =
          journalOptionsSearchQuery +
          `${index > 0 ? ' OR ' : ''}JOURNAL:("${value}")`
      })
      journalOptionsSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const journalOptionsBracketRequired = selectedOptions.length > 1
      return {
        journalOptionsSearchQuery,
        journalOptionsBracketRequired,
      }
    },
    createAffiliationSearchQuery() {
      const { affiliation } = this
      let affiliationSearchQuery = ''
      if (affiliation) {
        affiliationSearchQuery = `AFF:("${affiliation}")`
      }
      return affiliationSearchQuery
    },
    createAuthorsSearchQuery() {
      const { selectedAuthors } = this
      let authorOptionsSearchQuery = ''
      const selectedOptions = selectedAuthors.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value, orcidId }, index) => {
        authorOptionsSearchQuery =
          authorOptionsSearchQuery +
          `${index > 0 ? ' OR ' : ''}` +
          `${orcidId ? `AUTHORID:("${orcidId}")` : `AUTH:("${value}")`}`
      })
      authorOptionsSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const authorOptionsBracketRequired = selectedOptions.length > 1
      return {
        authorOptionsSearchQuery,
        authorOptionsBracketRequired,
      }
    },
    /**createPreprintServersSearchQuery() {
      const { preprintOptions } = this
      let preprintServersSearchQuery = ''
      preprintOptions
        .filter((item) => item.selected === true)
        .map(({ value }, index) => {
          preprintServersSearchQuery =
            preprintServersSearchQuery +
            `${index > 0 ? ' OR ' : ''}PUBLISHER:(${value})`
        })
      preprintServersSearchQuery.replace(' ', 'OR')
      return preprintServersSearchQuery
        ? '(SRC:PPR AND ' + `(${preprintServersSearchQuery}))`
        : ''
    },**/
    createArticleIdSearchQuery(articleIds, articleIdQuery) {
      const { articleIdOptions } = this
      const selectedArticleIdOption = articleIdOptions.find(
        (articleIdOption) => articleIdOption.selected === true
      )
      const selectedArticleIdOptionValue = articleIdQuery
        ? articleIdQuery
        : selectedArticleIdOption.value

      // Split the articleIds by comma and trim any whitespace
      const idsArray = articleIds.split(',').map((id) => id.trim())

      // Create the search query for each id and join them with ' OR '
      const articleIdSearchQuery = idsArray
        .map((id) =>
          selectedArticleIdOptionValue.replace(
            '*',
            selectedArticleIdOptionValue === 'DOI:(*)' ? `"${id}"` : id
          )
        )
        .join(' OR ')

      const articleIdQueryBracketRequired = idsArray && idsArray.length > 1
      return { articleIdSearchQuery, articleIdQueryBracketRequired }
    },
    createDatesSearchQuery() {
      const { dateOptions, fromDate, toDate } = this
      const selectedDateOption = dateOptions.find(
        (dateOption) => dateOption.selected === true
      )
      const selectedDateOptionValue = selectedDateOption.value
      const datesSearchQuery = `${selectedDateOptionValue}[${fromDate} TO ${toDate}]`

      return { datesSearchQuery }
    },
    getSelectedOptionForLogicalOperator(logicalOperator) {
      return this.options.find((item) => item.value === logicalOperator)
    },
    createOpenAccessOrCCLicenseSearchQuery() {
      const { openAccessOrCCLicenseOptions } = this
      let openAccessOrCCLicenseSearchQuery = ''
      const selectedOptions = openAccessOrCCLicenseOptions.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value }, index) => {
        openAccessOrCCLicenseSearchQuery =
          openAccessOrCCLicenseSearchQuery +
          `${index > 0 ? ' OR ' : ''}${value}`
      })
      openAccessOrCCLicenseSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const openAccessOrCCLicenseBracketRequired = selectedOptions.length > 1
      return {
        openAccessOrCCLicenseSearchQuery,
        openAccessOrCCLicenseBracketRequired,
      }
    },
    createFundingAgencySearchQuery() {
      const { selectedFundingAgencies } = this
      let fundingAgencyOptionsSearchQuery = ''
      const selectedOptions = selectedFundingAgencies.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value }, index) => {
        fundingAgencyOptionsSearchQuery =
          fundingAgencyOptionsSearchQuery +
          `${index > 0 ? ' OR ' : ''}GRANT_AGENCY:("${value}")`
      })
      fundingAgencyOptionsSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const fundingAgencyOptionsBracketRequired = selectedOptions.length > 1
      return {
        fundingAgencyOptionsSearchQuery,
        fundingAgencyOptionsBracketRequired,
      }
    },
    createGrantIdSearchQuery() {
      const { selectedGrantIds } = this
      let grantIdOptionsSearchQuery = ''
      const selectedOptions = selectedGrantIds.filter(
        (item) => item.selected === true
      )
      selectedOptions.map(({ value }, index) => {
        grantIdOptionsSearchQuery =
          grantIdOptionsSearchQuery +
          `${index > 0 ? ' OR ' : ''}GRANT_ID:("${value}")`
      })
      grantIdOptionsSearchQuery.replace(' ', 'OR')
      // Add outer bracket when multiple conditions are selected
      const grantIdOptionsBracketRequired = selectedOptions.length > 1
      return {
        grantIdOptionsSearchQuery,
        grantIdOptionsBracketRequired,
      }
    },
    exportQuery() {
      const content = this.createSearchQuery(this.query)
      const element = document.createElement('a')
      const file = new Blob([content], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = 'query.txt'
      document.body.appendChild(element) // Append anchor element to the body
      element.click() // Simulate click to trigger download
      document.body.removeChild(element) // Clean up
    },
    closeIntroductionModal() {
      this.showIntroductionModal = false
      localStorage.setItem('hasVisitedBetaAdvanceSearch', 'true')
    },
    closeNoSearchResultsModal() {
      this.showNoSearchResultsModal = false
      this.savedQuery = []
    },
    continueToSearchResults() {
      this.navigateToSearchQuery(this.savedQuery)
    },
    onCloseSearchTipsClick() {
      document.getElementById('search-tips').style.display = 'none'
    },
    onSearchTipsClick() {
      document.getElementById('search-tips').style.display = 'block'
    },
    onDateSelected(event, index) {
      event.preventDefault()
      const date = event.target.value
      if (index === 0) this.fromDate = date
      else this.toDate = date
    },
  },
}
</script>

<template>
  <div class="advanceSearchContainer">
    <div id="epmc_searchBanner"></div>
    <div class="grid-row" style="flex: 1">
      <div class="col-16">
        <div class="advanceSearchTitle">
          <div>
            <h1><i>Beta</i> Advanced search: Query builder</h1>
            <!--<p>
              {{ betaAdvanceSearchDescription }}
            </p> -->
            <!--<div id="advanceSearchActions">
              <action
                class="advanceSearchAction"
                icon-pos="left"
                @click="onActionButtonClick"
              >
                <i slot="icon" class="fas fa-caret-right" />How to build a query
              </action>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <action
                class="advanceSearchAction"
                icon-pos="left"
                @click="onActionButtonClick"
              >
                Advanced search tips
              </action>
            </div>
			-->
          </div>
        </div>
        <div class="has-sections">
          <content-section
            v-for="section in sections"
            :key="section.id"
            :section="section"
            @opened="onSectionOpened"
          >
            <template v-if="section.id === 'keywordsOrTopics'">
              <div class="">
                <div class="">
                  <form
                    id="topicsAndKeywordsForm"
                    @submit="onTopicsOrKeywordsSubmit"
                  >
                    <h5>
                      1. Enter one word or phrase at a time
                      <span
                        style="
                          font-weight: 100;
                          font-size: 14px;
                          font-style: italic;
                        "
                        ><a @click="onSearchTipsClick()"
                          >(See search tips)</a
                        ></span
                      >
                    </h5>
                    <div class="termsOrPhraseInput">
                      <dropdown
                        v-if="query.length > 0"
                        :class="[
                          'operatorDropdown',
                          logicalOperator.freeText.toLowerCase(),
                          getLogicalOperatorDropdownClass(),
                        ]"
                        :options="options"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        :selected="
                          getSelectedOptionForLogicalOperator(
                            logicalOperator.freeText
                          )
                        "
                        @onOptionSelected="
                          onLogicalOperatorChange($event, 'freeText')
                        "
                      />
                      <input
                        v-if="query.length === 0"
                        name="termOrPhrase"
                        :value="termOrPhrase"
                        required
                        @input="onTermOrPhraseChange"
                      />
                      <input
                        v-if="query.length > 0"
                        class="termInput"
                        name="termOrPhrase"
                        :value="termOrPhrase"
                        required
                        @input="onTermOrPhraseChange"
                      />
                    </div>
                    <p class="termsOrPhraseNote">
                      <span id="exactPhrase"
                        ><input
                          type="checkbox"
                          :checked="isFindExactPhraseEnabled"
                          :disabled="!termOrPhrase"
                          @input="onFindExactPhraseClick"
                        />
                        <find-exact-phrase-tooltip />
                      </span>
                    </p>
                    <!--<ul>
                      <li>
                        If multiple words are entered as separate ideas, add
                        upper case OR, AND, or NOT between them
                        <tips-tooltip />
                      </li>
                      <li>
                        If multiple words are entered as a phrase, select
                        <span id="exactPhrase"
                          ><input
                            type="checkbox"
                            :checked="isFindExactPhraseEnabled"
                            :disabled="!termOrPhrase"
                            @input="onFindExactPhraseClick"
                          />
                          <find-exact-phrase-tooltip />
                        </span>
                      </li>
                    </ul> -->

                    <h5 style="margin-top: 22px; margin-bottom: 0">
                      2. Select one or more places to search
                    </h5>
                    <div class="placesToSearch">
                      <div
                        :class="[
                          'checkbox',
                          defaultPlace.selected ? 'selectedCheckbox' : '',
                        ]"
                        @click="onDefaultPlaceToSearchClick()"
                      >
                        {{ defaultPlace.label }}
                        <sup>
                          <tooltip v-if="defaultPlace.information">
                            <i slot="trigger" class="far fa-question-circle" />
                            <b>{{ defaultPlace.label }}:</b>
                            {{ defaultPlace.information }}
                          </tooltip>
                        </sup>
                      </div>
                      <div style="margin-right: 8px">or</div>
                      <div
                        v-for="searchPlace in placesToSearch"
                        :key="searchPlace.id"
                        :class="[
                          'checkbox',
                          searchPlace.selected ? 'selectedCheckbox' : '',
                        ]"
                        @click="onPlaceToSearchClick(searchPlace.id)"
                      >
                        {{ searchPlace.label }}
                        <sup>
                          <tooltip v-if="searchPlace.information">
                            <i slot="trigger" class="far fa-question-circle" />
                            <b>{{ searchPlace.label }}:</b>
                            {{ searchPlace.information }}
                          </tooltip>
                        </sup>
                      </div>
                      <div class="multiSelectContainer">
                        <multi-select
                          class="articleSectionMultiSelect"
                          input-id="multi-select-article-section"
                          :options="articleSectionOptions"
                          :enable-tags="false"
                          placeholder="Sections in full text articles"
                          :multi-select-style="multiSelectStyle"
                          variant="caret-inside"
                          @onOptionChanged="onArticleSectionOptionsChanged"
                        />
                      </div>
                    </div>
                    <div class="submitButton">
                      <button type="submit" :disabled="!isAnyItemSelected">
                        <i class="fa fa-plus"></i>&nbsp;
                        <span v-if="query.length > 0"> Add </span>
                        <span v-else>Start building my query</span>
                      </button>
                    </div>
                    <div></div>
                  </form>
                </div>
              </div>
            </template>
            <template
              v-if="section.id === 'publicationTypeOrJournalOrArticleId'"
            >
              <form
                id="publicatiobTypeOrJournalOrArticleIdForm"
                @submit="onPublicationTypeOrJournalOrArticleIdSubmit"
              >
                <p>Use these filters to NARROW your search.</p>
                <div class="dateType">
                  <div>
                    <h5>Dates</h5>
                    <div class="dropdownContainer">
                      <dropdown
                        v-if="query.length > 0"
                        :class="[
                          'operatorDropdownSmall',
                          logicalOperator.dates.toLowerCase(),
                        ]"
                        :options="options"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        :selected="
                          getSelectedOptionForLogicalOperator(
                            logicalOperator.dates
                          )
                        "
                        @onOptionSelected="
                          onLogicalOperatorChange($event, 'dates')
                        "
                      />
                      <dropdown
                        class="dateOptionsDropdown"
                        :options="dateOptions"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        default-option-text="Select date type"
                        :selected-option-style="selectedOptionStyle"
                        @onOptionSelected="onDateOptionChange($event)"
                      />
                    </div>
                  </div>
                  <div>
                    <h5>From</h5>
                    <!--<date-picker
                      id="fromDate"
                      class="date-picker-from"
                      placeholder="dd/mm/yyyy"
                      :date="dates[0]"
                      @selected="onDateSelected($event, 0)"
                    />-->
                    <input
                      class="dateInput"
                      type="date"
                      :max="toDate"
                      name="from"
                      :value="fromDate"
                      @change="onDateSelected($event, 0)"
                    />
                  </div>
                  <div>
                    <h5>To</h5>
                    <!--<date-picker
                      id="toDate"
                      class="date-picker-from"
                      placeholder="dd/mm/yyyy"
                      :date="dates[1]"
                      @selected="onDateSelected($event, 1)"
                    /> -->
                    <input
                      class="dateInput"
                      type="date"
                      :min="fromDate"
                      :max="currentDate"
                      name="to"
                      :value="toDate"
                      @change="onDateSelected($event, 1)"
                    />
                  </div>
                </div>
                <div class="publicationType">
                  <div class="allPublicationTypes">
                    <h5>
                      Publication types from all sources
                      <sup>
                        <tooltip>
                          <i slot="trigger" class="far fa-question-circle" />
                          This filter includes the most popular publication
                          types, and searches the aggregated Europe PMC content
                          from PubMed, PMC, Agricola, Chinese Biological
                          Abstracts, the NCBI bookshelf. and Preprint servers.
                        </tooltip>
                      </sup>
                    </h5>
                    <div class="dropdownContainer">
                      <dropdown
                        v-if="query.length > 0"
                        :class="[
                          'operatorDropdownSmall',
                          logicalOperator.allPublicationTypes.toLowerCase(),
                        ]"
                        :options="options"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        :selected="
                          getSelectedOptionForLogicalOperator(
                            logicalOperator.allPublicationTypes
                          )
                        "
                        @onOptionSelected="
                          onLogicalOperatorChange($event, 'allPublicationTypes')
                        "
                      />
                      <multi-select
                        class="articleSectionMultiSelect"
                        input-id="multi-select-all-publication-type"
                        :options="allPublicationTypeOptions"
                        :enable-tags="false"
                        :placeholder="allPublicationTypeOptionsPlaceholder"
                        multi-select-style="width: 280px"
                        variant="caret-outside"
                        @onOptionChanged="onAllPublicationTypeOptionsChanged"
                      />
                    </div>
                  </div>
                </div>
                <div class="articleIdSection">
                  <div class="articleIdType">
                    <h5>ID type<span>(including DOI)</span></h5>
                    <div class="dropdownContainer">
                      <dropdown
                        v-if="query.length > 0"
                        :class="[
                          'operatorDropdownSmall',
                          logicalOperator.articleId.toLowerCase(),
                        ]"
                        :options="options"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        :selected="
                          getSelectedOptionForLogicalOperator(
                            logicalOperator.articleId
                          )
                        "
                        @onOptionSelected="
                          onLogicalOperatorChange($event, 'articleId')
                        "
                      />
                      <dropdown
                        class="articleIdOptionsDropdown"
                        :options="articleIdOptions"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        default-option-text="Select ID type"
                        :selected-option-style="selectedOptionStyle"
                        @onOptionSelected="onArticleIdOptionChange($event)"
                      />
                    </div>
                  </div>
                  <div class="articleId">
                    <h5>
                      ID# <span>(Separate multiple IDs with commas.)</span>
                    </h5>
                    <input
                      name="articleId"
                      :value="articleId"
                      placeholder="Enter ID"
                      @input="onArticleIdChange"
                    />
                  </div>
                </div>
                <div class="submitButton">
                  <button type="submit" :disabled="!isAnyItemSelected">
                    <i class="fa fa-plus"></i>&nbsp;
                    <span v-if="query.length > 0"> Add </span>
                    <span v-else>Start building my query</span>
                  </button>
                </div>
              </form>
            </template>
            <template
              v-if="section.id === 'freeFullTextAccessOrFundingOrLicense'"
            >
              <form
                id="freeFullTextAccessOrFundingOrLicenseForm"
                @submit="onFreeFullTextAccessOrFundingOrLicenseSubmit"
              >
                <div class="freeFullTextAccess">
                  <h5>Free full text access</h5>
                  <div class="dropdownContainer">
                    <dropdown
                      v-if="query.length > 0"
                      :class="[
                        'operatorDropdownSmall',
                        logicalOperator.freeFullTextAccess.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          logicalOperator.freeFullTextAccess
                        )
                      "
                      @onOptionSelected="
                        onLogicalOperatorChange($event, 'freeFullTextAccess')
                      "
                    />
                    <dropdown
                      class="freeFullTextAccessOptionsDropdown"
                      :options="freeFullTextAccessOptions"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      default-option-text="Select a type of free access"
                      :selected-option-style="selectedOptionStyle"
                    />
                  </div>
                </div>
                <div class="openAccessOrCCLicense">
                  <h5>Open access or CC license</h5>
                  <div class="dropdownContainer">
                    <dropdown
                      v-if="query.length > 0"
                      :class="[
                        'operatorDropdownSmall',
                        logicalOperator.openAccessOrCCLicense.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          logicalOperator.openAccessOrCCLicense
                        )
                      "
                      @onOptionSelected="
                        onLogicalOperatorChange($event, 'openAccessOrCCLicense')
                      "
                    />
                    <multi-select
                      class="openAccessOrCCLicenseMultiSelect"
                      input-id="multi-select-open-access-or-cc-license"
                      :options="openAccessOrCCLicenseOptions"
                      :enable-tags="false"
                      :placeholder="openAccessOrCCLicenseOptionsPlaceholder"
                      multi-select-style="width: 280px"
                      variant="caret-outside"
                      @onOptionChanged="onOpenAccessOrCCLicenseOptionsChanged"
                    />
                  </div>
                </div>
                <div class="fundingAgency">
                  <h5>Funding agency</h5>
                  <div class="dropdownContainer">
                    <dropdown
                      v-if="query.length > 0"
                      :class="[
                        'operatorDropdownSmall',
                        logicalOperator.fundingAgency.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          logicalOperator.fundingAgency
                        )
                      "
                      @onOptionSelected="
                        onLogicalOperatorChange($event, 'fundingAgency')
                      "
                    />
                    <multi-select
                      input-id="multi-select-funding-agency"
                      :options="fundingAgencyOptions"
                      :selected="selectedFundingAgencies"
                      :enable-tags="false"
                      :placeholder="fundingAgencyOptionsPlaceholder"
                      multi-select-style="width: 310px"
                      :autocomplete="true"
                      :loading="fundingAgenciesLoading"
                      @onOptionChanged="onFundingAgencyOptionsChanged($event)"
                      @user-input-changed="
                        debouncedHandleFundingAgencyInputChange
                      "
                    />
                    <div v-if="fundingAgenciesLoading" class="loadingContainer">
                      <loading margin="0" width="28" />
                    </div>
                  </div>
                </div>
                <div class="grantId">
                  <h5>Grant ID</h5>
                  <div class="dropdownContainer">
                    <dropdown
                      v-if="query.length > 0"
                      :class="[
                        'operatorDropdownSmall',
                        logicalOperator.grantId.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          logicalOperator.grantId
                        )
                      "
                      @onOptionSelected="
                        onLogicalOperatorChange($event, 'grantId')
                      "
                    />
                    <multi-select
                      input-id="multi-select-grant-id"
                      :options="grantIdOptions"
                      :selected="selectedGrantIds"
                      :enable-tags="false"
                      :placeholder="grantIdOptionsPlaceholder"
                      multi-select-style="width: 310px"
                      :autocomplete="true"
                      :loading="grantIdsLoading"
                      @onOptionChanged="onGrantIdOptionsChanged($event)"
                      @user-input-changed="debouncedHandleGrantIdInputChange"
                    />
                    <div v-if="grantIdsLoading" class="loadingContainer">
                      <loading margin="0" width="28" />
                    </div>
                  </div>
                </div>
                <div class="submitButton">
                  <button type="submit" :disabled="!isAnyItemSelected">
                    <i class="fa fa-plus"></i>&nbsp;
                    <span v-if="query.length > 0"> Add </span>
                    <span v-else>Start building my query</span>
                  </button>
                </div>
              </form>
            </template>
            <template
              v-if="section.id === 'filterByAuthorOrAffiliationOrJournal'"
            >
              <form
                id="filterByAuthorOrAffiliationOrJournalForm"
                @submit="onFilterByAuthorOrAffiliationOrJournalSubmit"
              >
                <div class="authors">
                  <h5>Author (or ORCID)</h5>
                  <div class="dropdownContainer">
                    <dropdown
                      v-if="query.length > 0"
                      :class="[
                        'operatorDropdownSmall',
                        logicalOperator.authors.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          logicalOperator.authors
                        )
                      "
                      @onOptionSelected="
                        onLogicalOperatorChange($event, 'authors')
                      "
                    />
                    <multi-select
                      input-id="multi-select-authors"
                      :options="authorOptions"
                      :selected="selectedAuthors"
                      :enable-tags="false"
                      :placeholder="authorOptionsPlaceholder"
                      multi-select-style="width: 337px"
                      :autocomplete="true"
                      :loading="authorsLoading"
                      @onOptionChanged="onAuthorOptionsChanged($event)"
                      @user-input-changed="debouncedHandleAuthorInputChange"
                    />
                    <div v-if="authorsLoading" class="loadingContainer">
                      <loading margin="0" width="28" />
                    </div>
                  </div>
                </div>
                <h5>Affiliation</h5>
                <div class="affiliation">
                  <dropdown
                    v-if="query.length > 0"
                    :class="[
                      'operatorDropdownSmall',
                      logicalOperator.affiliation.toLowerCase(),
                      getLogicalOperatorDropdownClass(),
                    ]"
                    :options="options"
                    :with-separator="false"
                    dropdown-icon-type="chevron"
                    :selected="
                      getSelectedOptionForLogicalOperator(
                        logicalOperator.affiliation
                      )
                    "
                    @onOptionSelected="
                      onLogicalOperatorChange($event, 'affiliation')
                    "
                  />
                  <input
                    v-if="query.length === 0"
                    name="affiliation"
                    :value="affiliation"
                    placeholder="Enter affiliation"
                    type="text"
                    @input="onAffiliationChange"
                  />
                  <input
                    v-if="query.length > 0"
                    class="affiliationInput"
                    name="affiliation"
                    :value="affiliation"
                    placeholder="Enter affiliation"
                    type="text"
                    @input="onAffiliationChange"
                  />
                </div>
                <div class="journals">
                  <h5>Journal</h5>
                  <div class="dropdownContainer">
                    <dropdown
                      v-if="query.length > 0"
                      :class="[
                        'operatorDropdownSmall',
                        logicalOperator.journals.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      dropdown-icon-type="chevron"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          logicalOperator.journals
                        )
                      "
                      @onOptionSelected="
                        onLogicalOperatorChange($event, 'journals')
                      "
                    />
                    <multi-select
                      input-id="multi-select-journals"
                      :options="journalOptions"
                      :selected="selectedJournals"
                      :enable-tags="false"
                      :placeholder="journalOptionsPlaceholder"
                      multi-select-style="width: 337px"
                      :autocomplete="true"
                      :loading="journalsLoading"
                      @onOptionChanged="onJournalOptionsChanged($event)"
                      @user-input-changed="debouncedHandleJournalInputChange"
                    />
                    <div v-if="journalsLoading" class="loadingContainer">
                      <loading margin="0" width="28" />
                    </div>
                  </div>
                </div>
                <div class="submitButton">
                  <button type="submit" :disabled="!isAnyItemSelected">
                    <i class="fa fa-plus"></i>&nbsp;
                    <span v-if="query.length > 0"> Add </span>
                    <span v-else>Start building my query</span>
                  </button>
                </div>
              </form>
            </template>
          </content-section>
        </div>
      </div>
    </div>
    <div id="searchQueryBottomSheet" class="searchQuerySection">
      <div class="grid-row">
        <div class="col-16">
          <div class="searchQueryTitle">
            <h3>Search Query:</h3>
          </div>
        </div>
      </div>
      <div class="searchQueryRowsContainer">
        <div class="grid-row">
          <div class="col-16">
            <div v-if="query.length > 0" class="searchQueryActions">
              <div class="searchButtonContainer">
                <button class="regular" @click="onSearchQueryClick()">
                  <i class="fa fa-search"></i
                  ><span id="search-btn-text">&nbsp;Search</span>
                </button>
              </div>
              <div>
                <a @click="exportQuery">Export</a> |
                <a @click="onClearQueryClick">Clear all</a>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-row">
          <div class="col-16">
            <div class="searchQueryRows">
              <p v-if="query.length === 0" class="noQueryText">
                Enter a search query above.
              </p>
              <div
                v-for="(item, index) in query"
                :key="item.id"
                class="searchQueryRowContainer"
              >
                <div style="display: flex; width: 100%; align-items: center">
                  <div class="searchQueryRow col-13">
                    <div
                      v-if="index === 0 && query.length > 1"
                      style="display: flex"
                    >
                      <div v-for="(item, queryIndex) in query" :key="item.id">
                        <div v-if="queryIndex > 0" class="queryBracket">(</div>
                      </div>
                    </div>
                    <dropdown
                      v-if="item.logicalOperator"
                      :class="[
                        'operatorDropdown',
                        'operatorDropdownSmall',
                        item.logicalOperator.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      :selected="
                        getSelectedOptionForLogicalOperator(
                          item.logicalOperator
                        )
                      "
                      dropdown-icon-type="chevron"
                      @onOptionSelected="
                        onQueryLogicalOperatorChange($event, item.id)
                      "
                    />
                    <span
                      ref="editableContent"
                      class="queryHtmlContainer"
                      v-html="renderQueryHtml(item)"
                    ></span>
                    <div v-if="index > 0" class="queryBracket endBracket">
                      )
                    </div>
                  </div>
                  <i
                    style="margin-right: 8px; font-size: 20px"
                    class="fas fa-times"
                    @click="onDeleteRowClick(item.id)"
                  />
                  <tooltip
                    v-if="item.term"
                    trigger="click"
                    placement="top"
                    :close="closeEditTermTooltip"
                  >
                    <i
                      slot="trigger"
                      style="margin-right: 8px; font-size: 14px"
                      class="fas fa-pen"
                      @click="closeEditTermTooltip = false"
                    />
                    <b><p style="margin-top: 0">Edit Term</p></b>
                    <form @submit="onRowContentChange($event, item)">
                      <input
                        required
                        name="rowTerm"
                        style="margin-right: 8px"
                        :value="item.term"
                      />
                      <button
                        style="
                          background: white;
                          border: none;
                          padding: 0;
                          margin: 0;
                          min-width: 10px;
                        "
                      >
                        <i type="submit" class="fas fa-check" />
                      </button>
                    </form>
                  </tooltip>
                </div>
                <p
                  :class="['count', item.countLoading ? 'disabled' : '']"
                  @click="onSearchQueryClick(item.query, item.count)"
                >
                  {{ item.count ? item.count.toLocaleString() : 0 }}
                </p>
              </div>
            </div>
            <div
              id="totalResults"
              :class="['totalCount', countLoading ? 'disabled' : '']"
            >
              <span v-if="query.length > 0" @click="onSearchQueryClick()">
                RESULTS:
                {{ totalCount.toLocaleString() }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showIntroductionModal && !isMobileScreen"
      @close="closeIntroductionModal"
    >
      <template slot="title">Impact the usability of Advanced search!</template>
      {{ betaAdvanceSearchDescription }}
      <div style="text-align: right; margin-top: 20px">
        <button @click="closeIntroductionModal">Ok</button>
      </div>
    </modal>
    <modal
      v-if="showNoSearchResultsModal"
      no-header
      type="warning"
      @close="closeNoSearchResultsModal"
    >
      <template slot="title">Warning</template>
      Your query, or a part of it, has zero results. Consider editing your
      query.
      <div style="text-align: right; margin-top: 20px">
        <button style="margin-right: 10px" @click="closeNoSearchResultsModal">
          Edit Query
        </button>
        <button class="secondary" @click="continueToSearchResults()">
          Continue to Search results
        </button>
      </div>
    </modal>
    <div id="search-tips">
      <div id="epmc_searchBanner"></div>
      <div class="grid-row">
        <div class="col-12">
          <div class="search-tip-header">
            <h2>Search tips</h2>
            <i class="fas fa-times" @click="onCloseSearchTipsClick()" />
          </div>
          <div class="search-tips-content">
            <h3>
              Expand search by adding synonyms
              <span style="font-weight: 100">(select OR)</span>
            </h3>
            <p>
              Adding synonyms, such as feline in addition to cat, helps expand
              your search to find papers that use different words for the same
              topic. Enter and Add your first word, then Add a synonym using the
              OR drop-down selection. Only one of terms must be present to
              appear in search results.
            </p>
            <div class="diagram">
              <img src="@/assets/or-ellipse.png" />
              <p>
                <span style="font-size: 20px; font-weight: bold">OR</span>
                broadens a search, only one term must be present.
              </p>
            </div>
          </div>
          <div class="search-tips-content">
            <h3>
              Narrow search by adding topics
              <span style="font-weight: 100">(select AND)</span>
            </h3>
            <p>
              You can narrow your search to only papers that contain two or more
              topics, such as cat and toxoplasmosis. Enter and Add your first
              word, then Add another topic using the AND drop-down selection.
              Only papers with both topics will be returned in search results.
            </p>
            <div class="diagram">
              <img src="@/assets/and-ellipse.png" />
              <p>
                <span style="font-size: 20px; font-weight: bold">AND</span>
                narrows a search, both terms must be present.
              </p>
            </div>
          </div>
          <div class="search-tips-content">
            <h3>
              Refine search by excluding a word
              <span style="font-weight: 100">(select NOT)</span>
            </h3>
            <p>
              To find papers that exclude certain words, Add the excluded term
              using the NOT drop-down selection. For example you may want to
              find a paper that includes cat but excludes dog.
            </p>
            <div class="diagram">
              <img src="@/assets/not-ellipse.png" />
              <p>
                <span style="font-size: 20px; font-weight: bold">NOT</span>
                refines a search, one term must be present, while the other term
                is excluded from the results.
              </p>
            </div>
          </div>
          <div class="search-tips-content" style="padding-bottom: 40px">
            <h3>Layer concepts to build a more complex query</h3>
            <p>
              You can build a more complex search query in layers. For example,
              you can look for a topic and it’s synonyms, then add another
              topic, and then exclude a word. Each concept will be grouped by
              the Query builder, creating a layered effect. The resulting search
              query might look like this: (((cat OR feline) AND toxoplasmosis)
              NOT dog). This would find papers that contain either cat or
              feline, as well as toxoplasmosis, but not dog.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.advanceSearchContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: left;
  #search-tips {
    margin-bottom: $base-unit * 4;
    .grid-row {
      display: flex;
      justify-content: center;
    }
    position: fixed;
    display: none;
    background: white;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: scroll;
    .search-tip-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      i {
        color: #20699c;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .search-tips-content {
      .diagram {
        img {
          margin-right: 10px;
        }
        display: flex;
        align-items: center;
      }
    }
  }
  .advanceSearchTitle {
    margin-top: $base-unit * 10;
    padding-left: $base-unit * 5;
    padding-right: $base-unit * 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      margin-top: $base-unit * 2;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.79px;
      text-align: left;
      color: $epmc-darker-grey;
    }
    #advanceSearchSmTitle {
      display: none;
    }
    #advanceSearchActions {
      display: flex;
      align-items: center;
      .advanceSearchAction {
        display: flex;

        i {
          font-size: $base-unit * 5;
        }
      }
    }
  }
  .close-icon {
    float: right;
    i {
      font-size: $base-unit * 5;
    }
  }

  .has-sections {
    .col-12 {
      margin-left: 0;
    }
  }

  .termsOrPhraseInput,
  .affiliation {
    display: flex;
    height: ($base-unit * 9) + 2;
    margin-left: $base-unit * 5;
    .termInput,
    input {
      border: 1px solid #a7a6a6;
      width: 430px;
    }
    .termInput,
    .affiliationInput {
      border-left: none;
    }
  }
  .affiliation {
    margin-left: 0;
    .affiliationInput,
    input {
      border: thin solid lighten($epmc-darker-grey, 45%);
      width: 331px;
    }
  }
  .termsOrPhraseNote {
    margin: 0;
    margin-top: $base-unit + 2;
    margin-left: $base-unit * 5;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: lighten($epmc-darker-grey, 10%);
  }
  ul {
    margin: 0px 0 16px;
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 21.79px;
      color: lighten($epmc-darker-grey, 10%);
      margin: 3px 0;
    }
  }
  #exactPhrase {
    color: $epmc-darker-blue;
    cursor: pointer;
    input {
      accent-color: $epmc-darker-blue;
    }
  }

  .placesToSearch {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: $base-unit * 5;
    .checkbox {
      border: 1px solid #1376ae;
      padding: $base-unit * 2 $base-unit * 6;
      color: #1376ae;
      font-weight: 600;
      font-size: $base-unit * 4;
      cursor: pointer;
      margin-right: $base-unit * 2;
      margin-top: $base-unit + 2;
      i {
        font-size: $base-unit * 3;
      }
    }
    .checkbox:last-of-type {
      border-right: 1px solid #1376ae;
    }
    .selectedCheckbox {
      background-color: $epmc-light-blue;
    }
    .multiSelectContainer {
      margin-top: $base-unit + 2;
    }
  }

  .submitButton {
    margin-top: $base-unit * 8;
    margin-left: 0;
  }
  #topicsAndKeywordsForm .submitButton {
    margin-left: $base-unit * 5;
  }

  h5,
  h6 {
    margin-bottom: $base-unit + 2;
    font-style: normal;
  }
  .searchQuerySection {
    margin-top: $base-unit * 5;
    width: 100%;
    bottom: 0;
    .searchQueryTitle {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      width: $base-unit * 43 + 3;
      background-color: $epmc-light-blue;
      h3 {
        margin: 0;
      }
    }
    .searchQueryRowsContainer {
      background-color: $epmc-light-blue;
      padding: 20px;
      padding-bottom: 100px;
      .searchQueryActions {
        padding-left: $base-unit * 5;
        .searchButtonContainer {
          display: flex;
          justify-content: flex-end;
        }
        a {
          font-size: $base-unit * 4;
          font-weight: 600;
          line-height: 21.79px;
        }
      }
      .searchQueryRows {
        padding: ($base-unit * 5) 0 ($base-unit * 4) ($base-unit * 5);
        .noQueryText {
          font-size: 16px;
          font-style: italic;
          font-weight: 400;
          line-height: 21.79px;
          color: #aaa2a2;
        }
        .searchQueryRowContainer {
          display: flex;
          align-items: center;
          .searchQueryRow {
            display: flex;
            align-items: center;
            min-height: $base-unit * 8 + 1;
            background: $epmc-white;
            border: 1px solid lighten($epmc-darker-grey, 50%);
            padding-right: $base-unit;
            margin-right: $base-unit * 2;
            position: relative;
            span {
              padding-left: $base-unit;
            }
            .queryHtmlContainer {
              white-space: nowrap;
              overflow-x: auto;
              scrollbar-width: thin;
            }
            .queryBracket {
              border: 1px solid $epmc-darker-blue;
              color: $epmc-darker-blue;
              background: white;
              font-size: $base-unit * 4 + 2;
              font-weight: 600;
              line-height: 21.79px;
              padding: $base-unit + 1;
            }
            .endBracket {
              position: absolute;
              right: 0;
            }
          }
          i {
            cursor: pointer;
            color: #1376ae;
            font-size: $base-unit * 4;
          }
          .count {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            color: #1376ae;
          }
          .disabled {
            color: lighten($epmc-darker-grey, 45%);
            pointer-events: none;
          }
          &:last-of-type {
            border-bottom: 1px solid lighten($epmc-darker-grey, 50%);
            padding-bottom: $base-unit * 2;
          }
        }
      }
      .totalCount {
        text-align: right;
        span {
          cursor: pointer;
          font-size: $base-unit * 4;
          font-weight: 600;
          line-height: $base-unit * 4 + 2;
          color: #20699c;
        }
      }
      .disabled {
        pointer-events: none;
        span {
          color: lighten($epmc-darker-grey, 45%);
        }
      }
    }
  }
  .articleIdOptionsDropdown {
    width: $base-unit * 42 + 2 !important;
  }
  .freeFullTextAccessOptionsDropdown {
    width: $base-unit * 78 !important;
  }
  .operatorDropdown {
    background: $epmc-light-green;
    width: $base-unit * 28 !important;
  }
  .operatorDropdownSmall {
    width: $base-unit * 21 !important;
    font-size: $base-unit * 3 + 2;
  }
  .or {
    background: $epmc-light-green;
  }
  .and {
    background-color: #f7fcba;
  }
  .not {
    background-color: #f2e8e8;
  }

  .dateType {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .dateOptionsDropdown {
      width: $base-unit * 50 !important;
    }
    .dateInput {
      padding: $base-unit + 1;
      border: thin solid lighten($epmc-darker-grey, 45%);
      width: $base-unit * 35;
    }
  }

  .publicationType {
    display: flex;
    flex-wrap: wrap;
    .allPublicationTypes {
      margin-right: $base-unit * 10;
    }
  }

  .publicationType,
  .journals,
  .affiliations,
  .authors,
  .articleIdType,
  .openAccessOrCCLicense,
  .fundingAgency,
  .grantId {
    .dropdownContainer {
      display: flex;
      align-items: center;
      .loadingContainer {
        margin-left: $base-unit * 2;
      }
    }
    a {
      font-size: 12px;
      font-weight: normal;
    }
    h5 {
      span {
        font-size: 14px;
        font-weight: 400;
        color: $epmc-darker-grey;
        margin-left: $base-unit;
      }
    }
  }
  .articleIdSection {
    display: flex;
    flex-wrap: wrap;
    .articleId {
      margin-left: $base-unit * 4;
      h5 {
        display: flex;
        span {
          font-size: 14px;
          font-weight: 400;
          color: $epmc-darker-grey;
          margin-left: $base-unit;
        }
      }
      input {
        width: 126px;
        border: thin solid lighten($epmc-darker-grey, 45%);
        padding: 6px;
      }
      input::placeholder {
        color: #aaa2a2;
        font-size: $base-unit * 3 + 2;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-smallest) {
  .advanceSearchTitle {
    align-items: unset !important;
    #advanceSearchSmTitle {
      display: block !important;
    }
  }
  #advanceSearchActions {
    align-items: unset !important;
    .advanceSearchAction {
      font-size: $base-unit * 3;
    }
  }
  .close-icon {
    i {
      font-size: $base-unit * 4 !important;
    }
  }
  .operatorDropdown,
  .termsOrPhraseInput,
  .termsOrPhraseNote,
  .placesToSearch,
  .submitButton {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: $breakpoint-extra-small) {
  .articleIdSection {
    .articleId {
      margin-left: 0 !important;
      input {
        width: 93% !important;
      }
    }
  }
}
</style>
